@import '../../assets/mixin.scss';

.task_header{
    padding: 0px 34px;
    background-color: transparent !important;
    position: sticky;
    top: 0px;
    z-index: 10;
    nav{
        background-color: var(--white_color);
        margin: 35px 0px 16px 0px;
        border-radius: 7px;
        height: 74px;
        padding: 0px 18px;
        white-space: nowrap;
        @include scrollProp;
        ul{
            width: 100%;
            height: 100%;
            @include flexBetweenProp;
            li{
                height: 100%;
                .nav-link{
                    @include flexProp;
                    gap: 10px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #CAD1DB;
                    text-transform: capitalize;
                    height: 100%;
                    padding: 0px 20px;
                    position: relative;
                    svg{
                        width: 30px;
                        height: 30px;
                    }
                    &::before{
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 0%;
                        height: 8px;
                        background-color: #155898;
                        border-radius: 50px 50px 0px 0px;
                        transition: all .5s ease-in-out;
                    }
                    &.active{
                        color: #155898;
                        &::before{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 980px){
    .task_header{
        background-color: var(--white_color) !important;
        padding: 0px;
        box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.12);
        nav{
            border-radius: 0px;
            margin-top: 0px;
            padding: 0px 4px;
            margin-bottom: 8px;
        }
    }
}
@import "../../../assets/mixin.scss";

header {
  background-color: var(--white_color);
  z-index: 1;
  position: sticky;
  top: 0px;
  border-bottom: 1px solid #e6e6e6;
  z-index: 5555;
  .max_container {
    height: 75px;
    @include flexBetweenProp;
    padding: 16px 34px;
  }
  .header_nav {
    @include flexProp;
    gap: 10px;
    .brand_logo {
      height: 60px;
    }
  }
}

.userProfile {
  position: relative;
  cursor: pointer;
  .dropdownToggle {
    @include flexProp;
    gap: 6px;
    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }
    .user_profile_details {
      h4 {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: var(--dark_color);
      }
      h6 {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
      }
    }
    .toggleIcon {
      width: 28px;
      height: 28px;
      color: var(--dark_color);
      margin-left: 8px;
      transition: all 0.5s ease-in-out;
      &.active {
        transform: rotate(-180deg);
      }
    }
  }
  .dropdownMenu {
    position: absolute;
    padding: 10px 5px;
    background-color: var(--main_color);
    top: 50px;
    right: 0;
    border-radius: 4px;
    display: block;
    box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.4);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(20px);
    max-width: max-content;
    transition: all 200ms linear;
    &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
      transform: translateY(0px);
    }
    li {
      a {
        padding: 5px 20px;
        display: block;
        white-space: nowrap;
        color: var(--white_color);
        &:hover {
          opacity: 0.8;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: var(--main_color);
      box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.4);
      transform: rotate(45deg);
      top: -8px;
      right: 12px;
    }
    .user_profile_details {
      padding: 0px 20px;
      padding-bottom: 15px;
      margin-bottom: 8px;
      border-bottom: 1px solid var(--border_color);
      h4 {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: var(--white_color);
        text-transform: capitalize;
        white-space: nowrap;
      }
      h6 {
        font-weight: 200;
        font-size: 10px;
        line-height: 15px;
        color: var(--white_color);
        text-transform: capitalize;
        // opacity: 0.9;
      }
    }
  }
}

.loading-google-spinner {
  position: relative;
  margin: 20px auto;
  width: 50px;
}
.loading-google-spinner:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.loading-google-spinner__circle-svg {
  -webkit-animation: loading-google-spinner-rotate 1.28973s linear infinite;
  animation: loading-google-spinner-rotate 1.28973s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loading-google-spinner__circle-stroke {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: loading-google-spinner-dash 2s ease-in-out infinite,
    loading-google-spinner-color 8s ease-in-out infinite;
  animation: loading-google-spinner-dash 2s ease-in-out infinite,
    loading-google-spinner-color 8s ease-in-out infinite;
  stroke-linecap: round;
  stroke-width: 5px !important;
}

@-webkit-keyframes loading-google-spinner-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading-google-spinner-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loading-google-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes loading-google-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@-webkit-keyframes loading-google-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

@keyframes loading-google-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

.loader-gif {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loader-gif > img {
  mix-blend-mode: darken;
  width: 10%;
}

/* custom_loader css */
.table-loader {
  border: 2px solid white;
  border-radius: 50%;
  border-top: 2px solid #5c5c5c;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 640px) {
  .table-loader {
    width: 15px;
    height: 15px;
  }
}

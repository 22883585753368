@import "../src/assets/mixin.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0.3px;
  scrollbar-width: thin;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
}
.verify-date {
  color: #337357 !important;
  > svg > path {
    stroke: #337357 !important;
  }
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.app {
  font-family: var(--poppins_font);
  color: var(--text_color);
  font-style: normal;
  min-width: 375px;
  width: 100%;
}

.cross-icon {
  width: 12px;
}

.cross-icon-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 2222;
}

.view-cta-pending {
  background-color: rgba(93, 169, 255, 1) !important;
  color: white;
  padding: 5px 10px;
  border-radius: 2px;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.header-task-modal {
  position: relative !important;
  top: 0;
  > button {
    position: fixed !important;
    top: 0;
    right: 0;
    > img {
      width: 20px;
    }
  }
}

@media screen and (max-width: 992px) {
  .cross-icon-container {
    position: absolute;
    top: 126%;
    transform: translateY(-50%);
    right: 70px;
    z-index: 2222;
  }
}

.custom_btn {
  background-color: var(--main_color) !important;
  box-shadow: 0px 60px 24px rgba(21, 88, 152, 0.02),
    0px 34px 20px rgba(21, 88, 152, 0.06), 0px 15px 15px rgba(21, 88, 152, 0.1),
    0px 4px 8px rgba(21, 88, 152, 0.12), 0px 0px 0px rgba(21, 88, 152, 0.12);
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: var(--white_color);
  border-radius: 4px;
  border: none;
  outline: none;
  text-transform: capitalize;
  /* padding: 20px 30px; */
  height: 48px;
  /* width: ; */
  padding: 0px 24px;
  @include flexProp;
  gap: 10px;
  white-space: nowrap;
  transition: all 0.5s ease;
  &:active {
    opacity: 0.6;
    transform: scale(0.9);
  }
}

.page_header_row {
  @include flexBetweenProp;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px 34px;
  .page_heading {
    h2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 45px;
      text-transform: uppercase;
      color: var(--dark_color);
    }
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
    }
  }
}

// .filter_container{}
.header_actions {
  // @include flexProp;
  // align-items: flex-start;
  // flex-wrap: wrap;
  @include flexBetweenProp;
  gap: 10px;
  // padding: 0px 34px;
  margin-bottom: 16px;
  .menu {
    position: relative;
    .menu_toggle {
      background-color: var(--white_color);
      @include flexBetweenProp;
      border-radius: 7px;
      height: 48px;
      padding: 15px;
      &.search_menu_toggle {
        padding: 0px;
        position: relative;
        .icon {
          position: absolute;
          display: block;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
        }
      }
      input {
        height: 48px;
        padding: 15px;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background: transparent;
        &::placeholder {
          color: #ccc;
          font-weight: 400;
          text-transform: capitalize;
        }
      }
      h6 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--text_color);
        text-transform: capitalize;
      }
      .icon {
        // width: 30px;
        // height: 30px;
        // fill: var(--text_color);
        display: block;
      }
    }
    .subMenu {
      position: absolute;
      width: 100%;
      background-color: var(--white_color);
      border-top: 2px solid var(--bg_color);
      border-radius: 0px 0px 7px 7px;
      padding: 5px 15px;
      transform: translateY(20px);
      opacity: 0;
      visibility: hidden;
      z-index: 99;
      transition: all 0.5s ease-in-out;
      &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(-8px);
      }
      li {
        font-size: 16px;
        text-transform: uppercase;
        padding: 5px 0px;
        transition: all 0.5s ease-in-out;
        &:hover {
          color: var(--dark_color);
        }
      }
    }
    &.search_menu {
      // width: 309px;
      // max-width: 768px;
      max-width: 70%;
      width: 100%;
      .icon {
        opacity: 0.6;
      }
    }
    &.date_filter_menu {
      // max-width: 146px !important;
      // width: 146px !important;
      max-width: 15%;
      width: 100%;
      .icon {
        fill: var(--dark_color) !important;
      }
    }
  }
  button {
    max-width: 15%;
    width: 100%;
    @include flexCenterProp;
  }
}

@media only screen and (max-width: 980px) {
  .page_header_row {
    display: none;
  }
  .header_actions {
    padding: 0px 15px;
    flex-wrap: wrap;
    justify-content: flex-start;
    .menu {
      &.search_menu {
        // max-width: 50%;
        max-width: initial;
      }
      &.date_filter_menu {
        // max-width: 20%;
        max-width: initial;
      }
    }
    button {
      max-width: initial !important;
      width: auto;
      position: fixed;
      bottom: 28px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

// ========= button click css ============
button {
  border: none;
  transition: all 0.5s ease-in-out;
  &:active {
    opacity: 0.6;
    transform: scale(0.9);
  }
}

// ====================
.desktop_view {
  display: block;
}
.mobile_view {
  display: none;
}

@media only screen and (max-width: 980px) {
  .desktop_view {
    display: none;
  }
  .mobile_view {
    display: block;
  }
}

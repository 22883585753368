@import "../../assets/mixin.scss";

// ========== scroll_container ==========
.taskManager_container {
  padding: 0px 34px;
  .task_container {
    @include flexProp;
    align-items: flex-start;
    @include scrollProp;
    scroll-behavior: smooth;
    gap: 20px;
  }
  .task_card {
    max-width: 350px;
    min-width: 350px;
    width: 100%;
    max-height: 964px;
    height: 800px;
    // height: 100%;
    background-color: #fff8e0;
    border-radius: 7px;
    border: 2px solid;
    border-color: #ffe785;
    // padding: 16px 12px;
    padding: 0px 6px;
    overflow: hidden;

    .task_header {
      padding: 16px 6px 16px 6px !important;
      // z-index: 0;
      background-color: transparent !important;
      position: unset;
      @include flexBetweenProp;
      box-shadow: none;
      .task_title {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: var(--dark_color);
      }
      .task_count {
        display: block;
        // width: 26px;
        height: 26px;
        background-color: var(--dark_color);
        color: var(--white_color);
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        padding: 10px;
        @include flexCenterProp;
      }
    }
    .task_body {
      width: 100%;
      height: 92%;
      overflow-y: auto;
      // margin-bottom: 12px;
      padding: 0px 6px 0px 6px;
      // padding-bottom: 50px;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        // background-color: #999999;
        background-color: rgba(153, 153, 153, 0.1);
        border-radius: 2rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--dark_color);
        // width: 6px;
        transform: scale(1.5);
        border-radius: 2rem;
        // border: 0.1px solid var(--white_color);
      }
      .task_box {
        background-color: var(--white_color);
        border: 1px solid var(--border_color);
        margin: 14px 0px;
        padding: 12px 0px;
        border-radius: 7px;
        opacity: 1 !important;
        cursor: grab;
        &:last-child {
          margin-bottom: 0px;
        }
        &:first-child {
          margin-top: 0px;
        }
        @include flexProp;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        .task_details {
          padding: 0px 12px;
          padding-bottom: 0px;
          @include flexProp;
          flex-direction: column;
          align-items: flex-start;
          gap: 14px;
          width: 100%;
          .user_id {
            display: block;
            padding: 8px 12px;
            border-radius: 5px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: var(--dark_color);
            background-color: #ffe785;
          }
          .task_heading {
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: var(--dark_color);
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
          }
          .task_desc {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
          }
          .task_time {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: var(--red_color);
            @include flexProp;
            gap: 8px;
          }
          .task_remaining_time {
            width: 100%;
            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              @include flexBetweenProp;
              margin-bottom: 8px;
              span {
                font-size: 10px;
                color: var(--dark_color);
              }
            }
            .progress {
              height: 6px;
              border-radius: 50px;
              background-color: #f7f9fa;
              .progress-bar {
                // background-color: #FF9F00;
                border-radius: 50px;
              }
            }
          }
        }
        .line {
          display: block;
          width: 100%;
          height: 1px;
          background-color: var(--border_color);
        }
        .user_details {
          width: 100%;
          @include flexBetweenProp;
          padding: 0px 12px;
          // padding-bottom: 12px;
          img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
          .user_name_position {
            .user_name {
              font-weight: 500;
              font-size: 16px;
              line-height: 18px;
              color: var(--dark_color);
              text-transform: capitalize;
            }
            .user_position {
              font-weight: 400;
              font-size: 10px;
              line-height: 15px;
            }
          }
          .user_actions {
            @include flexBetweenProp;
            gap: 4px;
            button {
              width: 36px;
              height: 36px;
              // padding: 8px;
              border-radius: 10px;
              color: var(--white_color);
              @include flexCenterProp;
              border: none;
              &.cmnt_btn {
                background-color: var(--sky_color);
              }
              &.delete_btn {
                background-color: var(--red_light_color);
              }
              &.default_btn {
                background-color: var(--bg_color);
              }
            }
          }
        }
      }
    }

    &.height_auto {
      max-height: auto;
      height: auto;
      .task_body {
        height: auto;
        overflow: hidden;
        // @include flexProp;
        // align-items: flex-start;
        // flex-direction: column;
        // gap: 0px;
      }
    }
  }
}

.blank_task_box {
  width: 100%;
  height: 288px;
  border: 2px dashed;
  @include flexCenterProp;
  margin-top: 6px;
  border-radius: 7px;
  h6 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 6px;
    word-wrap: 40px;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 980px) {
  .taskManager_container {
    padding: 0px;
    .task_container {
      padding: 0px 15px;
      padding-bottom: 30px;
    }
  }
}

.taskManager_container .task_card .task_body .task_box .task_details {
  overflow-x: hidden;
}

// ================== custom_modal ====================
.custom_modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  // padding: 20px 0px;
  // background-color: rebeccapurple;
  @include flexCenterProp;
  .custom_modal_backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(31, 31, 31, 0.66);
    // backdrop-filter: blur(2px);
    // z-index: 99;
  }

  .custom_modal_container {
    max-height: 80vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    max-width: 420px;
    width: 100%;
    padding: 18px 15px;
    background-color: var(--white_color);
    border-radius: 0px;
    background: #fff;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
      0px 17px 37px 0px rgba(0, 0, 0, 0.1),
      0px 67px 67px 0px rgba(0, 0, 0, 0.09),
      0px 150px 90px 0px rgba(0, 0, 0, 0.05),
      0px 267px 107px 0px rgba(0, 0, 0, 0.01),
      0px 417px 117px 0px rgba(0, 0, 0, 0);
    z-index: 10;
    margin: 20px 0px;
    // overflow-y: scroll;
    // text-align: center;
    .custom_modal_header {
      text-align: center;
      margin-bottom: 15px;
      h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        color: var(--dark_color);
      }
    }
    // .custom_modal_body {
    //   max-height: 80vh;
    //   padding-top: 15px;
    //   @include scrollProp;
    // }
    .custom_modal_footer {
      @include flexCenterProp;
      gap: 16px;
      // position: sticky;
      // z-index: 999;
      // bottom: 0px;
      background-color: var(--white_color);
      // background-color: red;
      padding-top: 15px;
      button {
        height: 48px;
        padding: 0px 44px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-transform: capitalize;
        &.cancel_btn {
          background-color: var(--border_color);
          color: var(--dark_color);
        }
        &.submit_btn {
          background-color: var(--main_color);
          color: var(--white_color);
        }
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .custom_modal {
    min-width: 375px;
    max-height: 100vh;
    min-height: 100%;
    // .custom_modal_backdrop{
    //     min-width: 375px;
    // }
    .custom_modal_container {
      max-width: 90%;
      .custom_modal_header {
        h4 {
          font-size: 16px;
        }
        h6 {
          font-size: 12px;
        }
      }
    }
  }
}

// ================= form css =========
form {
  .custom_input_field {
    position: relative;
    margin-bottom: 36px;
    &.last_input_field {
      margin-bottom: 22px;
    }
    .icon {
      position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
      top: 13px;
      right: 15px;
      pointer-events: none;
    }
    label {
      position: absolute;
      height: 28px;
      padding: 0px 8px;
      top: -14px;
      left: 14px;
      line-height: 15px;
      color: var(--main_color);
      @include flexCenterProp;
      background-color: var(--white_color);
      text-transform: capitalize;
      z-index: 1;
      font-family: "Poppins";
      font-weight: 500;
      font-size: 10px;
    }
    .input_field {
      border: 1px solid #155898;
      width: 100%;
      outline: none;
      border-radius: 4px;
      height: 54px;
      padding: 10px 15px;
      color: var(--dark_color);
      font-size: 14px;
      font-family: "Poppins";
      font-weight: 400;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      box-sizing: border-box;
      &.date_input_field::-webkit-inner-spin-button,
      &.date_input_field::-webkit-calendar-picker-indicator {
        // display: none;
        opacity: 0;
        -webkit-appearance: none;
      }
    }

    // .input_field:disabled ~ label{
    //     background-color: red !important;
    // }

    // .date_input_field ~ span.icon{
    //     top: 27px;
    // }
    textarea {
      height: auto !important;
    }
  }
  .form_error {
    text-transform: capitalize;
    font-size: 14px;
  }
}

select::-ms-expand {
  display: none;
}

// ============ delete_modal ==================
.delete_modal,
.restore_modal {
  .custom_modal_container {
    max-width: 336px;
    border-radius: 14px;
    position: relative;
    .custom_modal_body {
      text-align: center;
      padding-bottom: 25px;
      i {
        margin-bottom: 10px;
        display: block;
      }
      h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: var(--dark_color);
        text-transform: uppercase;
        margin-bottom: 14px;
      }
      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: var(--dark_color);
        text-transform: capitalize;
      }
    }
  }
}

// ============== master_task_page ======================
.master_task_page {
  .user_list_card {
    background-color: var(--white_color);
    border: none;
    position: sticky;
    left: 0px;
    .task_header {
      // background-color: red !important;
      margin: 14px 5px;
      padding: 0px;
    }
    .task_body {
      padding: 0px;
      ul {
        li {
          a {
            padding: 14px 0px;
          }
        }
      }
    }
  }
}

// ============ user_list =============
.user_list {
  li {
    @include flexProp;
    flex-direction: column;
    align-items: flex-start;
    // border-bottom: 1px solid #EBEBEB;
    border-radius: 7px;
    padding: 0px 15px;
    overflow: hidden;
    width: 100%;
    transition: all 0.5s ease-in;
    span.line {
      display: block;
      width: 100%;
      height: 1px;
      background-color: #ebebeb;
      border-radius: 2rem;
    }
    &:last-child {
      span.line {
        display: none;
      }
    }
    a {
      @include flexProp;
      gap: 6px;
      padding: 14px 10px !important;
      width: 100%;
      transition: all 0.5s ease-in-out;
      border-radius: 7px;
      img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        object-fit: cover;
        overflow-clip-margin: unset;
        overflow: hidden;
      }
      .user_details {
        h4 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--dark_color);
          text-transform: capitalize;
        }
        h6 {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: var(--text_color);
        }
      }
      &.active {
        background-color: #ebf4ff;
      }
    }
    a.active ~ span.line {
      display: none;
    }
  }
}

// ======= search_input ========
.task_card {
  .task_header {
    &.search_input {
      position: sticky !important;
      top: -5px;
      overflow: hidden;
      // padding: 0px !important;
      padding: 14px 15px 6px 15px !important;
      margin: 0px !important;
      background-color: var(--white_color) !important;
      input {
        display: block;
        width: 100%;
        height: 48px;
        border-radius: 7px;
        background-color: #f7f7f7;
        padding: 18px 14px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--dark_color);
        text-transform: capitalize;
        border: none;
        outline: none;
        &::placeholder {
          color: var(--text_color);
        }
      }
      svg {
        // width: 30px;
        height: 100%;
        position: absolute;
        top: 58%;
        transform: translateY(-50%);
        right: 30px;
        transition: all 0.5s ease-in-out;
      }
      input:focus ~ {
        svg {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}

// ================= deletedTask_card =================
.table_card {
  background-color: var(--white_color);
  border-radius: 7px;
  padding: 25px 27px;
  @include scrollProp;
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      height: 50px;
      border-radius: 7px;
      tr {
        th {
          border: none;
          padding: 20px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          background-color: var(--main_color);
          color: var(--white_color);
          text-align: center;
          text-transform: capitalize;
          &:first-child {
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            white-space: nowrap;
          }
          &:last-child {
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
          }
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #d8e0eb;
        &:last-child {
          border: none;
        }
        td {
          text-align: center;
          padding: 30px 20px;
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          text-transform: capitalize;
          color: var(--dark_color);
          button {
            background-color: transparent;
            svg {
              path {
                transition: all 0.5s ease-in-out;
              }
            }
            // transition: all .5s ease-in-out;
            &:active {
              svg {
                path {
                  opacity: 0.6;
                  transform: scale(0.9);
                }
              }
            }
          }
          &.request_action {
            text-align: center;
            button {
              display: block;
              height: 18px;
              border-radius: 2px;
              color: var(--white_color);
              @include flexCenterProp;
              font-weight: 500;
              font-size: 8px;
              line-height: 12px;
              margin: 2px auto;
              width: 54px;
              text-transform: capitalize;
              transition: none;
              &:active {
                opacity: 0.2;
                transform: scale(0.9);
              }
              &.approve_btn {
                background: #0bfc15;
              }
              &.decline_btn {
                background: var(--red_color);
              }
              &.embed_btn {
                background: var(--sky_color);
              }
            }
          }
        }
      }
    }
  }
  // &.request_extension_table_card{
  //     table{
  //         tbody{
  //             tr{
  //                 td{
  //                     text-align: left;
  //                 }
  //             }
  //         }
  //     }
  // }
}

@media only screen and (max-width: 980px) {
  .deletedTask_page,
  .request_extension_page {
    // background-color: red;
    padding: 0px 15px 15px 15px;
  }
}

// ========= custom_pagination ==========
.custom_pagination_container {
  max-width: 100%;
  position: relative;
}
ul.custom_pagination {
  max-width: 80%;
  width: 100%;
  margin: 0px auto;
  margin-top: 24px;
  @include flexProp;
  gap: 5px;
  @include scrollProp;
  .pagination_item {
    padding: 10px 14px;
    border-radius: 4px;
    height: 32px;
    @include flexCenterProp;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark_color);
    }
    &.active {
      background-color: var(--main_color) !important;
      span {
        color: var(--white_color) !important;
      }
    }
    &:hover {
      background-color: #e5f3ff;
    }
    .pagination_btn {
      padding: 0px;
      background-color: transparent;
      &:active {
        svg {
          opacity: 0.6;
          transform: scale(0.9);
        }
      }
      &:hover {
        svg {
          path {
            stroke: var(--main_color);
          }
        }
      }
    }
    &.prev {
      margin-right: 10px;
      position: sticky;
      left: 0;
      background-color: var(--white_color);
      &:hover {
        background-color: transparent;
      }
    }
    &.next {
      position: sticky;
      right: -10px;
      background-color: var(--white_color);
      // margin-left: 10px;
      // background-color: transparent;
    }
  }
}

// =============== restore_modal ====================
.restore_modal {
  .custom_modal_container {
    max-width: 326px;
    .custom_modal_body {
      h4 {
        margin-bottom: 5px;
      }
      padding-bottom: 0px;
      padding-top: 0px;
    }

    .custom_modal_footer {
      button {
        color: var(--white_color) !important;
        @include flexCenterProp;
      }
      .cancel_btn {
        background-color: var(--red_color) !important;
      }
      .submit_btn {
        background-color: #0bfc15 !important;
      }
    }
  }
}

// ===================== approve_req_modal =====================
.approve_req_modal {
  .custom_modal_container {
    max-width: 326px;
    text-align: center;
    .custom_modal_body {
      padding-top: 0px;
      h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        color: var(--dark_color);
        margin-bottom: 14px;
      }
      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: var(--dark_color);
        margin-bottom: 14px;
      }
    }
    .custom_modal_footer {
      gap: 14px;
      button {
        color: var(--white_color) !important;
        max-width: 50%;
        width: 100%;
        &.cancel_btn {
          background-color: var(--red_color);
          &.secondary {
            background-color: var(--border_color);
            color: var(--dark_color) !important;
          }
        }
        &.submit_btn {
          background-color: var(--light_green);
        }
      }
    }
  }
}

// ================== approveRequestModal ==========
.approveRequestModal {
  .custom_modal_container {
    // max-width: 420px;
    .custom_modal_body {
      padding-top: 0px;
    }
    .custom_modal_footer {
      padding-top: 0px;
      .submit_btn {
        background-color: var(--light_green) !important;
      }
    }
  }
}
// =================== task_details_form =================
.task_details_form {
  padding-bottom: 15px;
  table {
    tr {
      border-bottom: none !important;
      th {
        color: var(--dark_color);
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;
      }
      td {
        text-align: left !important;
        padding: 10px 0px !important;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

// =============== pendingTaskActivity_page ======================
.pendingTaskActivity_page {
  .pending_task_container {
    .task_card {
      height: auto;
      background-color: var(--white_color);
      border: none;
      padding: 0px;
      // overflow: hidden;
      max-height: auto;
      // border-radius: 7px;
    }
    .pending_task_body {
      a {
        padding: 20px;
        @include flexBetweenProp;
        .p_task_detaiks {
          @include flexProp;
          gap: 12px;
          h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: var(--main_color);
          }
        }
        &.active {
          background-color: #ebf4ff;
        }
      }
    }
  }

  // ======== accordion ==============
  .accordion {
    border-radius: 7px;
    .accordion-item {
      border: none;
      .accordion-header {
        .accordion-button {
          @include flexBetweenProp;
          background-color: var(--white_color);
          position: relative;
          // border: none;
          box-shadow: none;
          border-bottom: 1px solid var(--border_color);
          &:active {
            transform: scale(1);
          }
          .accordion_btn_title {
            @include flexProp;
            gap: 12px;
            h4 {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: var(--main_color);
            }
          }
          .accordion_icon {
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            svg {
              transform: rotate(-90deg);
              transition: all 0.5s ease-in-out;
            }
          }
          &.collapsed {
            border: none;
            .accordion_icon {
              svg {
                transform: rotate(90deg);
              }
            }
          }
          &::after {
            background-image: none;
            color: var(--main_color);
          }
        }
      }
      .accordion-body {
        padding: 0px;
        padding-bottom: 10px;
        height: 355px;
        @include scrollProp;
      }
    }
    // .task_header{
    //     &.search_input{
    //         svg{
    //             right: 30px;
    //             top: 60%;
    //         }
    //     }
    // }
  }
}

@media screen and (max-width: 980px) {
  .search_menu {
    max-width: 100% !important;
  }
}

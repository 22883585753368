@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,500&display=swap");

@import "../src/assets/mixin.scss";

:root {
  --bg_color: #f5f5f5;
  --white_color: #ffffff;
  --main_color: #155898;
  --main_light: #d5eaff;
  --dark_color: #1c1c1c;
  --poppins_font: "Poppins", sans-serif;
  --text_color: #b3b3b3;
  --border_color: #f0f0f0;
  --red_color: #ff0000;
  --sky_color: #5da9ff;
  --red_light_color: #ff6666;
  --light_green: #0bfc15;
  --playFair_font: "Playfair Display", serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg_color) !important;
  // box-sizing: border-box;
  overflow-x: hidden;
}

.chat_items {
  height: 400px;
}

body,
html {
  transition: 0.5s ease-in-out;
  scroll-behavior: smooth;
}

.react-datepicker {
  font-family: "Poppins" !important;
}

.custom_modal_container {
  border-radius: 0px !important;
}

.go2534082608:after,
.go2534082608:before {
  background: black !important;
}

// /* Hide scrollbar for Chrome, Safari and Opera */
// .custom_modal_container::-webkit-scrollbar {
//   display: none;
// }

.css-b62m3t-container {
  z-index: 2;
}

.MuiRating-root > label {
  font-size: 2rem;
}

.alert-error {
  margin-top: -30px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
}

.select-member {
  z-index: 3 !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
// .custom_modal_container {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

a {
  text-decoration: none;
}

.css-13cymwt-control {
  border: none !important;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

.css-1fdsijx-ValueContainer {
  height: 48px !important;
}

ul {
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
}

.MuiTooltip-tooltip {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  background-color: #155898 !important;
  padding: 10px 10px 10px 10px !important;
}

.MuiTooltip-arrow {
  color: #155898 !important;
}

.max_container {
  max-width: 1366px;
  width: 100%;
  margin: 0px auto;
}

.page {
  padding: 34px 0px;
}

@media only screen and (max-width: 980px) {
  .page {
    padding: 0px;
  }
}

@import "../../assets/mixin.scss";

.login_page {
  width: 100vw;
  height: 100vh;
  @include flexCenterProp;
  background: url(../../assets/images//login_banner.png) no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 59, 100, 0.9);
    z-index: -1;
  }
  .login_box {
    max-width: 356px;
    width: 100%;
    background-color: var(--white_color);
    border-radius: 5px;
    background: var((--white_color));
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
      0px 16px 35px 0px rgba(0, 0, 0, 0.1),
      0px 64px 64px 0px rgba(0, 0, 0, 0.09),
      0px 143px 86px 0px rgba(0, 0, 0, 0.05),
      0px 255px 102px 0px rgba(0, 0, 0, 0.01),
      0px 398px 111px 0px rgba(0, 0, 0, 0);
    border: 1px solid #e6e6e6;
    padding: 20px 22px;
    position: relative;
    top: 0;
    .url-change-container {
      position: absolute;
      top: -10px;
      left: -20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 44px;
      width: 44px;
      height: 44px;
      background-color: #155898 !important;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
    }
    .logo {
      text-align: center;
      picture {
        display: block;
        height: 64px;
        margin-bottom: 16px;
        img {
          height: 100%;
        }
      }
      h2 {
        color: var(--dark_color);
        font-size: 32px;
        font-weight: 600;
        line-height: normal;
        padding-bottom: 16px;
        font-family: var(--playFair_font);
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: var(--dark_color);
        font-family: "Poppins";
        margin-top: -8px;
        margin-bottom: -10px;
      }
    }
    .login_form {
      padding: 32px 0px;
      padding-bottom: 0px;
      .custom_input_field {
        margin-bottom: 20px;
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 400;
      }
      label {
        position: relative;
        text-align: left;
        height: auto;
        padding: 0px;
        top: 0;
        left: 0;
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: var(--dark_color);
        padding-bottom: 10px;
        background: none;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
      }
      .submit_btn {
        margin: 0px auto;
        max-width: 225px;
        width: 100%;
        height: 48px;
        background-color: var(--main_color);
        color: var(--white_color);
        @include flexCenterProp;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        font-family: "Poppins";
        text-transform: uppercase;
        border-radius: 4px;
      }
    }
  }
}

.submit_btns {
  background-color: #155898 !important;
  height: 48px !important;
  width: 100% !important;
  font-family: "Poppins" !important;
  color: white !important;
  border-radius: 4px !important;
  //font:;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
  font-size: 14px;
  font-weight: 600;
  margin-top: -50px;
}

.submit_btn_cancel {
  margin-top: 15px;
  background-color: #c7c7c7;
  height: 48px !important;
  width: 100% !important;
  font-family: "Poppins" !important;
  color: black !important;
  border-radius: 4px !important;
  //font:;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
  font-size: 14px;
  font-weight: 600;
}

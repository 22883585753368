@import "../../assets/mixin.scss";

.chat_body {
  position: fixed;
  max-width: 400px;
  width: 100%;
  // width: 375px;
  height: calc(100% - 75px);
  right: 0px;
  bottom: 0;
  z-index: 33333;
  background-color: var(--white_color);
  box-shadow: -261px 0px 104px rgba(0, 0, 0, 0.01),
    -147px 0px 88px rgba(0, 0, 0, 0.05), -65px 0px 65px rgba(0, 0, 0, 0.09),
    -16px 0px 36px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
  .close_chat {
    display: block;
    position: absolute;
    top: 38px;
    left: -18px;
    cursor: pointer;
    z-index: 9999;
  }
}

.img-content-chat-container > a > img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
  overflow-clip-margin: unset;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.img-content-chat-container > a,
.img-content-chat-container > div > a {
  color: cyan;
  text-decoration: underline;
}

// =========== chat_content ===============
.chat_content {
  width: 100%;
  height: 100%;
  // overflow: scroll;
  // @include scrollProp;
  padding: 20px 0px;
  position: relative;
  .chat_header {
    padding: 16px 0px;
    text-align: center;
    .user_profile_container {
      img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        object-fit: cover;
        overflow-clip-margin: unset;
        overflow: hidden;
      }
      .user_profile_details {
        padding: 10px 0px 16px 0px;
        h4 {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--dark_color);
          text-transform: capitalize;
        }
        h6 {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: var(--text_color);
          text-transform: capitalize;
        }
      }
    }
  }
  .chat_item_body {
    height: 70vh !important;
    @include scrollProp;
    padding: 0px 20px;
    padding-bottom: 100px;
    .chat_items {
      padding-bottom: 20px;
      .chat_item {
        margin-bottom: 8px;
        // display: flex;
        // align-items: flex-end;
        // justify-content: flex-end;
        // transform-origin: right;
        &.me {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          transform-origin: right;
          .chat_item_content {
            background-color: var(--main_color);
            border-radius: 5px;
            // max-width: 50%;
            // min-width: 215px;
            .chat_msg {
              color: var(--white_color);
              text-align: right;
            }
          }
        }
        &.other {
          display: flex;
          flex-direction: column;

          align-items: flex-start;
          justify-content: flex-start;
          transform-origin: left;
          .chat_item_content {
            background-color: var(--main_light);
            border-radius: 22px 22px 22px 0px;
            // max-width: 50%;
            // min-width: 215px;
            .chat_msg {
              color: var(--dark_color);
              text-align: left;
            }
          }
        }
        &:last-child {
          margin-bottom: 150px;
        }
        .chat_item_content {
          padding: 8px 15px;
          .chat_msg {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
  }
  .chat_footer {
    position: absolute;
    width: 100%;
    background-color: var(--white_color);
    bottom: 0px;
    padding: 5px 20px;
    padding-bottom: 20px;
    // bottom: 40px;
    // left: 0px;
    // width: 100%;
    .file_preview {
      // background-color: green;
      background-color: var(--bg_color);
      position: absolute;
      bottom: 40px;
      width: 88%;
      height: 200px;
      border-radius: 11px;
      @include flexCenterProp;
      box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
      }
    }
    .send_new_msg {
      position: relative;
      height: 48px;
      @include flexCenterProp;
      flex-wrap: nowrap;
      gap: 12px;
      padding: 0px 14px;
      background-color: #eff1f2;
      border-radius: 32px;
      overflow: hidden;
      input {
        width: 80%;
        height: 100%;
        border: none;
        outline: none;
        background-color: transparent;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--dark_color);
        &::placeholder {
          text-transform: capitalize;
        }
      }
      button {
        border: none;
        outline: none;
        box-shadow: none;
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}

.created-chat-date {
  font-size: 11px;
}
